<template>
  <div class="spinnerContent" v-show="shouldDisplayLoading">
    <div class="spinner"></div>
  </div>
</template>
<script>
  export default {
    computed: {
      isApplicationLoading() {
        return this.$store.state.app.loadingState.length > 0;
      },
      shouldDisplayLoading() {
        return this.isApplicationLoading;
      }
    }
  };
</script>

<style lang="scss">

  .spinnerContent {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 500;
    top: 0;
    left: 0;
    background: #f4f4f4;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: center;
    -webkit-justify-content: center;
    align-content: center;
    -webkit-align-content: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .spinner {
    width: 4rem;
    height: 4rem;
    position: relative;
    perspective: 200px;
    &::before

  {
    display: block;
    content: '';
    width: 50%;
    height: 50%;
    background: #2a2a2a;
    animation: 2s flipWalker ease infinite;
  }

  }

  @keyframes flipWalker {
    0% {
      transform: translate(0, 0) rotateX(0) rotateY(0);
      background: #2a2a2a;
    }

    25% {
      transform: translate(100%, 0) rotateX(0) rotateY(180deg);
      background: #e20613;
    }

    50% {
      transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
      background: #2a2a2a;
    }

    75% {
      transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
      background: #e20613;
    }

    100% {
      transform: translate(0, 0) rotateX(0) rotateY(360deg);
      background: #2a2a2a;
    }
  }
</style>
